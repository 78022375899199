.list-costumer {
    display: flex;
    list-style: none;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    transition: 0.1s ease;
    padding: 5px 50px;
}
.list-costumer:hover {
    background-color: #cccccc;
    transition: ease 0.1s;
}
