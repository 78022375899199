.form-onderzoek {
    padding: 50px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form-onderzoek__section-left{
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 60%
}
.form-onderzoek__section-right{
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-left: 50px;
}
.form-set__column {
    display: flex;
    width: 80%;
    justify-content: space-between;
}
.form-set__column input, .form-set__column select {
    width: 40%;
    margin: 1px 0;
}
.form-onderzoek__naw-klant, .form-onderzoek__data {
    display: flex;
    flex-direction: column;
}
.form-container {
    display: flex;
    flex-direction: row;
}
.form-onderzoek__naw-klant label {
    justify-content: space-between;
}
.form-set__naw-klant {
    justify-content: space-between;
    display: flex;
    width:60%;
    margin: 5px 0;
}
#opmerkingen-input {
    margin: 10px
}
.form-opmerkingen {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}
.form-onderzoek__data {
    margin-top: 50px;
    width: 60%;
    display: flex;
    justify-content: space-between;
}
.unique-set {
    margin-bottom: 20px;
    width: 100% !important;
}
.unique-set label {
    width: 100%;
    margin-right: 20px;
}
.form-set__data {
    display: flex;
    justify-content: space-between;
    width: 135%;
}
#partij__1 {
    width: 50%;
}
#partij__2 {
    width: 150%;
}

.form-grid__data {  display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0 0;

    margin-top: 50px;
    grid-auto-flow: row;
    grid-template-areas:
    "empty krat-1 krat-2 krat-3 gemiddeld"
    "aantal aantal__krat-1 aantal__krat-2 aantal__krat-3 aantal__gem"
    "te-klein te-klein__krat-1 te-klein__krat-2 te-klein__krat-3 te-klein__gem"
    "te-groot te-groot__krat-1 te-groot__krat-2 te-groot__krat-3 te-groot__gem"
    "schade schade__krat-1 schade__krat-2 schade__krat-3 schade__gem"
    "zachtrot zachtrot__krat-1 zachtrot__krat-2 zachtrot__krat-3 zachtrot__gem"
    "penicillium penicillium__krat-1 penicillium__krat-2 penicillium__krat-3 penicillium__gem"
    "mijten mijten__krat-1 mijten__krat-2 mijten__krat-3 mijten__gem"
    "fusarium fusarium__krat-1 fusarium__krat-2 fusarium__krat-3 fusarium__gem"
    "steel steel__krat-1 steel__krat-2 steel__krat-3 steel__gem"
    "spruithoogte spruithoogte__krat-1 spruithoogte__krat-2 spruithoogte__krat-3 spruithoogte__gem"
    "water-schade water-schade__krat-1 water-schade__krat-2 water-scahde__krat-3 water-schade__gem"
    "bewort bewort__krat-1 bewort__krat-2 bewort__krat-3 bewort__gem"
    "dubbel-ns dubbel-ns__krat-1 dubbel-ns__krat-2 dubbel-ns__krat-3 dubbel-ns__gem"
    "gewicht gewicht__krat-1 gewicht__krat-2 gewicht__krat-3 gewicht__gem"
    "zwarte-spruit zwarte-spruit__krat-1 zwarte-spruit__krat-2 zwarte-spruit__krat-3 zwarte-spruit__gem"
    "droge-stof droge-stof__input . . .";
}

.empty { grid-area: empty; }

.krat-1 { grid-area: krat-1; }

.krat-2 { grid-area: krat-2; }

.krat-3 { grid-area: krat-3; }

.aantal { grid-area: aantal; }

.te-klein { grid-area: te-klein; }

.te-groot { grid-area: te-groot; }

.schade { grid-area: schade; }

.zachtrot { grid-area: zachtrot; }

.penicillium { grid-area: penicillium; }

.mijten { grid-area: mijten; }

.fusarium { grid-area: fusarium; }

.steel { grid-area: steel; }

.spruithoogte { grid-area: spruithoogte; }

.water-schade { grid-area: water-schade; }

.bewort { grid-area: bewort; }

.dubbel-ns { grid-area: dubbel-ns; }

.gewicht { grid-area: gewicht; }

.zwarte-spruit { grid-area: zwarte-spruit; }

.droge-stof { grid-area: droge-stof; }

.gemiddeld { grid-area: gemiddeld; }

.aantal__krat-1 { grid-area: aantal__krat-1; }

.aantal__krat-2 { grid-area: aantal__krat-2; }

.aantal__krat-3 { grid-area: aantal__krat-3; }

.aantal__gem { grid-area: aantal__gem; }

.te-klein__krat-1 { grid-area: te-klein__krat-1; }

.te-klein__krat-2 { grid-area: te-klein__krat-2; }

.te-klein__krat-3 { grid-area: te-klein__krat-3; }

.te-klein__gem { grid-area: te-klein__gem; }

.te-groot__krat-1 { grid-area: te-groot__krat-1; }

.te-groot__krat-2 { grid-area: te-groot__krat-2; }

.te-groot__krat-3 { grid-area: te-groot__krat-3; }

.te-groot__gem { grid-area: te-groot__gem; }

.schade__krat-1 { grid-area: schade__krat-1; }

.schade__krat-2 { grid-area: schade__krat-2; }

.schade__krat-3 { grid-area: schade__krat-3; }

.schade__gem { grid-area: schade__gem; }

.zachtrot__krat-1 { grid-area: zachtrot__krat-1; }

.zachtrot__krat-2 { grid-area: zachtrot__krat-2; }

.zachtrot__krat-3 { grid-area: zachtrot__krat-3; }

.zachtrot__gem { grid-area: zachtrot__gem; }

.penicillium__gem { grid-area: penicillium__gem; }

.mijten__gem { grid-area: mijten__gem; }

.fusarium__gem { grid-area: fusarium__gem; }

.steel__gem { grid-area: steel__gem; }

.spruithoogte__gem { grid-area: spruithoogte__gem; }

.water-schade__gem { grid-area: water-schade__gem; }

.bewort__gem { grid-area: bewort__gem; }

.dubbel-ns__gem { grid-area: dubbel-ns__gem; }

.gewicht__gem { grid-area: gewicht__gem; }

.zwarte-spruit__gem { grid-area: zwarte-spruit__gem; }

.droge-stof__input { grid-area: droge-stof__input; }

.penicillium__krat-1 { grid-area: penicillium__krat-1; }

.penicillium__krat-2 { grid-area: penicillium__krat-2; }

.penicillium__krat-3 { grid-area: penicillium__krat-3; }

.mijten__krat-1 { grid-area: mijten__krat-1; }

.mijten__krat-2 { grid-area: mijten__krat-2; }

.mijten__krat-3 { grid-area: mijten__krat-3; }

.fusarium__krat-1 { grid-area: fusarium__krat-1; }

.fusarium__krat-2 { grid-area: fusarium__krat-2; }

.fusarium__krat-3 { grid-area: fusarium__krat-3; }

.steel__krat-1 { grid-area: steel__krat-1; }

.steel__krat-2 { grid-area: steel__krat-2; }

.steel__krat-3 { grid-area: steel__krat-3; }

.spruithoogte__krat-1 { grid-area: spruithoogte__krat-1; }

.spruithoogte__krat-2 { grid-area: spruithoogte__krat-2; }

.spruithoogte__krat-3 { grid-area: spruithoogte__krat-3; }

.water-schade__krat-1 { grid-area: water-schade__krat-1; }

.water-schade__krat-2 { grid-area: water-schade__krat-2; }

.water-scahde__krat-3 { grid-area: water-scahde__krat-3; }

.bewort__krat-1 { grid-area: bewort__krat-1; }

.bewort__krat-2 { grid-area: bewort__krat-2; }

.bewort__krat-3 { grid-area: bewort__krat-3; }

.dubbel-ns__krat-1 { grid-area: dubbel-ns__krat-1; }

.dubbel-ns__krat-2 { grid-area: dubbel-ns__krat-2; }

.dubbel-ns__krat-3 { grid-area: dubbel-ns__krat-3; }

.gewicht__krat-1 { grid-area: gewicht__krat-1; }

.gewicht__krat-2 { grid-area: gewicht__krat-2; }

.gewicht__krat-3 { grid-area: gewicht__krat-3; }

.zwarte-spruit__krat-1 { grid-area: zwarte-spruit__krat-1; }

.zwarte-spruit__krat-2 { grid-area: zwarte-spruit__krat-2; }

.zwarte-spruit__krat-3 { grid-area: zwarte-spruit__krat-3; }

.form-grid__input {

}

.remove-research {
    background-color: red;
    border-radius: 8px;
    width: 10%;
    border: none;
    color: white;
    height: 20px;
    font-weight: bold;
}
.remove-research:hover {
    background-color: darkred;
}