.list-appointments {
    list-style: none;
    border: 1px solid black;
}

.background-color-list-true {
    background-color: #65B32E;
}
.background-color-list-false {
    background-color: white;
}