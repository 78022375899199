.popup-bloem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.popup-bloem__img-container {
    display: flex;
    justify-content: center;
}
.popup-bloem__info-container {
    padding: 20px 50px;
}