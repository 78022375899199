@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@300;400&display=swap');

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
}

p, span {
    font-family: 'Open Sans', sans-serif;
}
.navbar-container {
    width: 15%;
    background: none;
    height: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 24px;
    flex-shrink: 1;
    flex-grow: 1;
}

.navbar-item {
    display: flex;
    background: white;
    height: 60px !important;
    margin-bottom: 22px;
    width: 100%;
    align-items: center;
    box-shadow: rgba(0,0,0, 1) 1px 1px 4px;
    border-left: 5px solid black;
    border-radius: 5px;
    overflow: hidden;

}
.active-navbar {
    background: white;
    height: 60px;
    margin-bottom: 22px;
    display: flex;
    box-shadow: rgba(0,0,0, 1) 1px 1px 4px;
    border-left: 5px solid #65B32E;
    color: #65B32E;
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    transition: ease 0.5s;
    overflow: hidden;
    align-items: center;
    border-radius: 8px;
}
.navbar-item {
    color: black;
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    transition: ease 0.5s;
    z-index: 100;
}

.navbar-item:hover{
    color: #65B32E!important;
    width: 103%;
    transform: translateY(2px);
    transition: ease 0.5s;
}
.navbar-item-hidden {
    display: none;
    transition: ease 0.5s;
}

.navbar-item-history {
    background: rgba(0,0,0, 0.1);
    height: 60px;
    position: relative;
    z-index: -100;
    top: -25px;
    display: flex;
    box-shadow: rgba(0,0,0, 1) 1px 1px 4px;
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    transition: ease 1s;
    overflow: hidden;
    align-items: center;
    border-radius: 8px;
    animation-name: appear;
    animation-duration: 0.5s;
    text-decoration: none;
    color: black;
    text-align: center;
    margin-left: 2px;
}
.navbar-item-history h2 {
    padding: 0 28px;
}

.history-h2-active {
    color: #65B32E;
}
.history-h2 {
    color: #000;
}
.history-div-active {
    background: white;
    font-weight: 400;
}
.history-div {

}
@keyframes appear{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}


