.button-container-klanten {
    width: 100%;
    display: flex;
    background-color: rgba(0,0,0,1);
    height: 3%;
    text-decoration: none;
}
#voeg-klant-toe {
    width: 20%;
    display: block;
    color: white;
    background: #65B32E;
    border: none;

    position: relative;
    font-weight: 600;
    left: 80%;
}
#voeg-klant-toe:hover {
    background-color: darkgreen;
}
.klant-link {
    text-decoration: none;
    color: black;
    width: 100%;
}

