.select-dropdown {
    width: auto;
}
.relatie-list {
    width: auto;
    display: flex;
    padding: 10px 20px;
}
.relatie-list select {
    margin-left: 50px;
    text-align: center;
}

.relatie-list button {
    background: #65B32E;
    color: white;
    border: none;
    font-weight: 600;
    width: auto;
    margin-left: 5%;
}