.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(255,255,255,1) 55%, rgba(101,179,46,1) 100%);
}

.image-container {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: auto;
    height: 80%;
    transform: rotate(2deg);
}

.container-input {
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 100%;
}

.input-set-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
}

.input-set-login label {
    width: 8%;
    margin-right: 20px;
    text-align: right;
    font-weight: 600;
    color: black;
    font-size: 18px;
}

.input-set-login input {
    width: 10%;
    border: none;
    border-radius: 5px;
    padding: 0 5px;
}

#confirm-login {
    width: 10%;
    height: 20px;
    border-radius: 5px;
    background: saddlebrown;
    color: white;
    border: none;
}

.button-container-login {
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}