.form-set__modal {
    display: flex;
    flex-direction: column;
}
.form-set__modal input {
    width: 20%;
}
#opslaan {
    background: #65B32E;
    color: white;
    border: none;
    width: 10%;
    position: relative;
    left: 0;
    margin: 10px 0;
}