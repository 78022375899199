.grid-card {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
}
.grid-card::-webkit-scrollbar {
    width: 50px;
    height: 10px ;
}
