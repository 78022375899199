.button__bloem-aanmaken {
    background-color: #65B32E;
    border: none;
    color: white;
    font-size: 1em;
    border-radius: 8px;
    width: 10%;
    height: 30px;
    margin: 40px 0;
    float: right;
}
.button__bloem-aanmaken:hover {
    background-color: darkgreen;
}
.popup-bloem__form-set {
    display: flex;
    margin: 20px 0;
}
.popup-bloem__form-set label {
    width: 10%;
}
.popup-bloem__form-set input {
    width: 30%;
}
