.base-container {
    width: 80%;
    height: 90%;
    position: absolute;
    right: 23px;
    top: 23px;
    /*box-shadow: rgba(0,0,0, 1) 1px 1px 2px;*/
    border: 1px solid rgba(0,0,0, 0.2);
    border-radius: 5px;
}

.base-container::-webkit-scrollbar {
    width: 10px;
    height: 10px ;
}

.base-container::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.base-container::-webkit-scrollbar-track {
    color: #ddd;
    border: 1px rgba(0,0,0, 0.5) solid;
}

.base-container::-webkit-scrollbar-button {
    color: #65B32E;
    border-radius: 5px;
}
.greeting-home {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: #65B32E;
    font-weight: 600;
    margin-top: 20px;
}

.home-opkomende-afspraken {
    position: absolute;
    right: 23px;
    width: 20%;
    height: auto;
    top: 100px;
    border: rgba(0,0,0, 0.4) solid 1px;
    border-radius: 5px;
}

.h2-opkomende-afspraken {
    width: 100%;
    text-align: center;
    font-size: 1rem;
}

.container-afspraken {
    list-style: none;
    background: #65B32E;
}

.home-afspraak-button {
    background: #65B32E;
    color: white;
    border: none;
    display: flex;
    position: fixed;
    bottom: 10%;
    right: 5%;

    width: 10%;
    height: 3%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.home-afspraak-button:hover {
    background-color: darkgreen;
}