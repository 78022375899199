.bloem-item {
    text-decoration: none;
    list-style: none;
}
.bloem-item:hover {
    background-color: #f9f9f9;
    box-shadow: inset -1px -1px 4px rgba(0,0,0, 0.25);
}
.bloem-item h2{
    display: flex;
}
.bloem-item p {
    display: flex;
    flex-direction: column;
}
.picture-flower {
    width: 50%;
}