.form-set {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    margin-left: 50px;
    width: 60%;
    justify-content: space-between;
    margin: 10px 0;
}

.form-set label {
    width: 30%;
}
.form-set:last-child {
    display: flex;
}
.form-set input {
    width: 100%;
}

#probleem-verzenden {
    width: 10%;
    background-color: #65B32E;
    color: white;
    border: none;
    position: relative;
    left: 35%;
    font-weight: 600;
}
#probleem-verzenden:hover {
    background-color: darkgreen;
}
.form-probleem {
    width: 90%;
    height: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#textarea-probleem {
    width: 100%;
}