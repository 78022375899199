#bollen-zoeken {
    margin-top: 20px;
    width: 30%;
    border: rgba(0,0,0, 0.8) solid 1px;
    padding: 2px 10px;
    height: 30px;
    margin-bottom: 20px;
}

.searchbar {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.searchbar-wrapper {
    width: 20%;
    height: 30px;
    justify-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 10px;
    border: rgba(0,0,0, 0.2) solid 1px;
}

#bollen-zoeken {
    width: 100%;
    border: none;
}
#bollen-zoeken:active {
    border: none;
}
#bollen-zoeken:focus {
    border: none;
    outline: none;
}


#add-bloemen {
    margin-top: 25px;
    display: flex;
    background: #65B32E;
    position: absolute;
    color: white;
    border: none;
    width: 15%;
    right: 23px;
    height: 25px;
    justify-content: space-between;
    font-size: 1em;
    padding: 2px 30px;
    text-align: center;
    top:0;
}
#add-bloemen:hover {
    background-color: darkgreen;
}

.card-section {
    width: 100%;
    height: 95%;
    justify-content: center;
    justify-items: center;
    overflow: scroll;
}

.card-section::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

.card-section::-webkit-scrollbar-thumb {
    border-radius: 5px;
    color: #000;
    background: #65B32E;
    box-shadow: 1px 1px rgba(0,0,0, 0.2);
}

.card-section::-webkit-scrollbar-thumb:hover {
    background: darkgreen;
}

.card-section::-webkit-scrollbar-button {
    background-color: #65B32E;
    color: white;
    background-repeat: no-repeat;
    background-size: 50%;

}
.card-section::-webkit-scrollbar-button:horizontal {
    visibility: hidden;
}
.card-section::-webkit-scrollbar-track-piece {
    color: grey;
}
.card-section::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: #000000 transparent transparent transparent;
}

.card-section::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #ffffff transparent transparent transparent;
}
/* Up */
.card-section::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #000000 transparent;
}

.card-section::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #ffffff transparent;
}
.card-section::-webkit-scrollbar-button:single-button {
    background-color: #65B32E;
    display: block;
    border-style: solid;
}
.card-section::-webkit-scrollbar-button:single-button:hover {
    background-color: darkgreen;
    display: block;
    border-style: solid;
}
.bloemen-link {
    text-decoration: none;
    color: black;
}
