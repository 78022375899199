.ellipsis {
    display: flex;
    position: absolute;
    left: 96.5%;
    top: 10%;
    width: 5%;
    height: 50px;
}
.menubar-klant-show {
    border-left: 1px solid rgba(0,0,0, 0.2);
    width: 20%;
    position: absolute;
    right: 0;
    top: 10%;
    text-align: center;
    display: flex;
    z-index: 100;
    flex-direction: column;
    font-weight: 600;
}
.menubar-klant-hidden {
    display: none;
}
.menubar-klant__item:first-child {
    border-top: 1px solid rgba(0,0,0, 0.2);
}
.menubar-klant__item {
    height: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0, 0.2);
    z-index: 100;
    background-color: #ffffff;
}
.menubar-klant__item:hover {
    background-color: #f9f9f9;
}
.remove-klant {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: white;
    border: 1px solid rgba(0,0,0, 0.2);
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 1rem;

}
.remove-klant-hidden {
    display: none;
}
.remove-klant h2 {
    margin-top: 40px;
}
#button-remove-klant__confirm {
    background-color: #65B32E;
    color: #ffffff;
    margin-right: 40px;
}
#button-remove-klant__confirm:hover {
    background-color: darkgreen;
}
#button-remove-klant__deny {
    background-color: red;
    color: #ffffff;
}
#button-remove-klant__deny:hover {
    background-color: darkred;
}

#button-remove-klant__confirm, #button-remove-klant__deny {
    width: 160px;
    border: none;
    margin-bottom: 40px;
    font-size: 1rem;
    font-weight: bold;
    padding: 4px 20px;
}
.aanpassingen-klant {
    display: block;
}
.aanpassingen-klant-hidden {
    display: none;
}