.footer-container {
    display: flex;
    height: 5%;
    width: calc(100% - 46px);
    margin-left: 23px;
    margin-right: 23px;
    text-align: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
}

.footer-container p {
    display: flex;
    color: #65B32E;
    font-weight: 400;
}