.afspraken-popup {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.afspraken-popup__formset {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    width: 40%;
    justify-content: space-between;
}
.afspraken-popup__formset input {
    width: 50%;
}
.opslaan-afspraak {
    display: flex;
    background-color: #65B32E;
    color: white;
    border: none;
    height: 20px;
    width: 120px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 58%;
}