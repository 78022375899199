.close-modal {
    background: red;
    color: white;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 30px;
    height: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    font-weight: 600;
}
.close-modal:hover {
    background: darkred;
    transition: 0.2s;
    height: 32px;
    width: 32px;
}